export default function servicesWorkerEnabled() {
  if (!('serviceWorker' in navigator)) {
    return false;
  }

  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  if (publicUrl.origin !== window.location.origin) {
    return false; // not pwa, could e.g. be admin backend
  }

  return true;
}
