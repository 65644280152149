import { Capacitor } from '@capacitor/core';
import throttle from 'lodash.throttle';

window.Capacitor = Capacitor; // for some reason capacitor throws an error otherwise

(function() {
  if (!Capacitor.isNative) {
    return;
  }
  if (Capacitor.isPluginAvailable('SplashScreen')) {
    Capacitor.Plugins.SplashScreen.hide();
  }

  window.addEventListener('click', e => {
    if(!Capacitor.isPluginAvailable('Browser')) {
      return;
    }

    const link = e.target.closest('a');
    if(!link) {
      return;
    }
    const href = link.getAttribute('onclick') ? false : link.getAttribute('href');
    if(!href) {
      // no href, e-.g. cookie consent
      return;
    }

    if (href.substr(0, 1) !== '/' && href.substr(0, 20) !== 'http://wirmobile.at/' && href.substr(0, 21) !== 'https://wirmobile.at/') {
      // that's an external link
      e.preventDefault();
      Capacitor.Plugins.Browser.open({
        url: href,
        presentationStyle: 'fullscreen', // | "popover"
      });
      return;
    }
  });


  // if the app was in the background and we actually got a message, show the notifications
  function checkForNotification() {
    const PushNotifications = Capacitor.Plugins.PushNotifications;
    Capacitor.Plugins.PushNotifications.getDeliveredNotifications().then(result => {
      if(result.notifications > 0 && result.notifications.length > 0) {
        location.href = 'https://wirmobile.at/notifications';
        Capacitor.Plugins.PushNotifications.removeAllDeliveredNotifications();
      }
    });
  }

  checkForNotification();
  document.addEventListener('visibilitychange', checkForNotification);

  /*
  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  const PushNotifications = Capacitor.Plugins.PushNotifications;
  PushNotifications.requestPermission().then( result => {
    if (result.granted) {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      // Show some error
      console.error(result);
    }
  });

  // On success, we should be able to receive notifications
  PushNotifications.addListener('registration',
    (token) => {
      alert('Push registration success, token: ' + token.value);
    }
  );

  // Some issue with our setup and push will not work
  PushNotifications.addListener('registrationError',
    (error) => {
      alert('Error on registration: ' + JSON.stringify(error));
    }
  );

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener('pushNotificationReceived',
    (notification) => {
      alert('Push received: ' + JSON.stringify(notification));
    }
  );

  // Method called when tapping on a notification
  PushNotifications.addListener('pushNotificationActionPerformed',
    (notification) => {
      alert('Push action performed: ' + JSON.stringify(notification));
    }
  );
  */

  (function() {
    // update app once in a while, similar to redirectToHome
    // Keep track of last accessed time
    let lastAccessed = new Date();
    const updateLastAccessed = () => {
      lastAccessed = new Date();
    };
    const updateLastAccessedThrottled = throttle(updateLastAccessed, 1000);
    window.addEventListener('touchstart', updateLastAccessedThrottled);
    window.addEventListener('click', updateLastAccessedThrottled);

    document.addEventListener('visibilitychange', function() {
      const oneHourAgo = new Date();
      oneHourAgo.setHours(oneHourAgo.getHours() - 1);
      // If inactive for 1h, reload
      if (
        document.visibilityState === 'visible' &&
        lastAccessed < oneHourAgo
      ) {
        document.body.classList.add('body--is-closing');
        window.location.reload();
      }
    });
  }());

}());
