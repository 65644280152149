
import cal from "generate-calendar-url";

export default class EventPage {
  init() {
    const eventPage = document.querySelector('.event');
    if(!eventPage) {
      return;
    }

    document.addEventListener(window.innerWidth > 600 ? 'click' : 'touchstart', (e) => {
      // on dropdown click
      if (e.target.matches('.event-dropdown')) {
        e.preventDefault();
        e.stopPropagation();
        // toggle the calendar dropdown
        e.target.classList.toggle('active');
        return;
      }

      // on dropdown option click
      if (e.target.matches('.event-link')) {
        e.preventDefault();
        e.stopPropagation();
        // close the calendar dropdown
        const dropdown = document.querySelector('.event-dropdown');
        dropdown.classList.remove('active');
        // open the calendar
        const event = this.getEventData(dropdown);
        let link = '';
        switch (e.target.dataset.type) {
          case 'google':
            link = cal.google(event);
            break;
          case 'outlook':
            link = cal.outlook(event);
            break;
          case 'ics':
            link = cal.ical(event);
            break;
        }

        window.open(link, '_blank');
      }
    });
  }

  toDate(timestamp) {
    return new Date(parseInt(timestamp,10) * 1000);
  }

  getEventData(dropdown) {
    const startDate = this.toDate(dropdown.getAttribute('data-start'));
    const endDate = dropdown.getAttribute('data-end') ? this.toDate(dropdown.getAttribute('data-end')) : this.toDate(parseInt(dropdown.getAttribute('data-start'), 10) + 60*60);
    return {
      title: dropdown.getAttribute('data-title'),
      start: startDate,
      end: endDate,
      location: dropdown.getAttribute('data-location'),
      description: dropdown.getAttribute('data-description').replace('<br>', '<br><br>')
    };
  }
}
