
window.addEventListener('load', () => {
	Array.from(document.querySelectorAll('.codeq-survey-component')).forEach(component => {
		const form = component.querySelector('.codeq-survey-form');
		form.classList.add('codeq-survey-form--has-js');

		// Attempt to get item from local storage
		try {
			const storedValue = localStorage.getItem(component.getAttribute('id'));
		} catch (e) {
			// If there was an error, it's likely that local storage is disabled
			console.warn('LLocal storage not accessible due to browser settings. Not allowing filling out of survey.');
			form.classList.add('codeq-survey-form--was-submitted');
			form.innerHTML = 'Die Sicherheitseinstellungen in deinem Browser verhindern die Bewertung.';
			component.classList.add('codeq-survey-component--show-submission-results');
			return;
		}

		form.classList.add('codeq-survey-form--was-submitted');
		form.innerHTML = 'Du hast bereits eine Bewertung abgegeben.';
		component.classList.add('codeq-survey-component--show-submission-results');
	});

	function submitForm(form) {
		const xhr = new XMLHttpRequest();
		xhr.open('POST', form.getAttribute('action'), true);
		const formData = new FormData(form);
		xhr.send(formData);

		xhr.onreadystatechange = function() {
			if (xhr.readyState === 4 && xhr.status === 200) {
				form.classList.remove('codeq-survey-form--is-submitting');
				form.classList.add('codeq-survey-form--is-submitted');
				form.innerHTML = 'Erfolgreich bewertet';

				const component = form.parentElement.parentElement;
				component.classList.add('codeq-survey-component--show-submission-results');

				try {
					localStorage.setItem(component.getAttribute('id'), '1');
				} catch (e) {
					console.warn('Local storage not accessible due to browser settings. Not saving survey submission.');
				}
			}
		};
	}

	// on radio click, submit the form via ajax
	document.addEventListener('click', (e) => {
		if (e.target.matches('.codeq-survey-form__rating-label')) {
			// after the value is actually set, do an ajax submit
			const form = e.target.parentElement;
			form.classList.add('codeq-survey-form--is-submitting');
			setTimeout(() => submitForm(form), 100);
		}
	});
});
