
export default class Article {
  initAll() {
    /* we use a polyfill instead
    if (!window.navigator || !navigator.share) {
      Array.prototype.forEach.call(document.querySelectorAll('.article__share'), (el) => {
        el.remove();
      });
      return;
    }
    */

    const buttons = document.querySelectorAll('.article__share');
    Array.prototype.forEach.call(buttons, button => {
      button.addEventListener('click', async (e) => {
        e.preventDefault();
        this.onShareClick(button.closest('.article') || button.closest('.article-inner'));
      });
    });
  }

  onShareClick(article) {
    const titleEl = article.querySelector('.article__content-title');
    const excerptEl = article.querySelector('.article__content-excerpt');
    const shareLink = article.querySelector('.article__share').getAttribute('data-link');
    const linkElement = article.querySelector('.article__link') || article.querySelector('.betterembed__read-more');
    const articleLink = linkElement ? linkElement.getAttribute('href') : location.href;

    navigator.share({
      //title: titleEl ? titleEl.innerText : '',
      text: titleEl ? titleEl.innerText : excerptEl ? excerptEl.innerText : '',
      url: (shareLink || articleLink).replace('https://admin.wirmobile.at', 'https://wirmobile.at'),
    });
  }
}
