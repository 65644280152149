
export default class Notification {
  initAll() {
    if(document.body.classList.contains('neos-backend')) {
      return;
    }
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
    } catch (e) {
      console.warn('Local storage not accessible due to browser settings. Not loading notifications.');
      return;
    }

    let currentUnseenCount = 0;
    if(document.querySelector('.notifications')) {
      // on the notification page, mark all unread notifications as unread
      Array.prototype.forEach.call(document.querySelectorAll('.notification-component'), (notification) => {

        if (localStorage.getItem(notification.getAttribute('id')) !== 'read') {
          notification.classList.add('active');

          if (!localStorage.getItem(notification.getAttribute('id'))) {
            // unseen and unread, mark it as seen
            currentUnseenCount++;
            localStorage.setItem(notification.getAttribute('id'), 'seen');
          }

          // if the notification has no link, instantly mark it as read
          if (!notification.querySelector('.notification.notification--has-link')) {
            localStorage.setItem(notification.getAttribute('id'), 'read');
          }
        }
      });
    } else {
      currentUnseenCount = this.calculateUnseenCount();
    }

    // update the counters
    this.setUnseenCountInHeader(currentUnseenCount);
    this.showUnseenCountInInfoBlocks(currentUnseenCount);

    // listen for notification clicks
    document.addEventListener(window.innerWidth > 600 ? 'click' : 'touchstart', (e) => {
      if (e.target.closest('.notification.notification--has-link')) {
        let notificationComponent = e.target.closest('.notification.notification--has-link').parentElement;
        if(notificationComponent.classList.contains('active')) {
          localStorage.setItem(notificationComponent.getAttribute('id'), 'read');
        }
      }
    });
  }

  calculateUnseenCount() {
    // calculate the unread count based on notification.js
    if(!window.notifications) {
      return 0;
    }

    let unseenCount = 0;
    Array.prototype.forEach.call(window.notifications, (notification) => {
      if (!localStorage.getItem(notification.id)) {
        unseenCount++;
      }
    });
    return unseenCount;
  }

  setUnseenCountInHeader(count) {
    if(location.pathname === '/notifications') {
      // not on notifications page
      count = 0;
    }
    document.querySelector('.notifications-bell').innerHTML = count===0 ?
      'Keine Benachrichtigungen' :
      '<span class="notifications-bell__counter">' + count + '</span>' + (count===1 ? ' Benachrichtigung' : ' Benachrichtigungen');
  }

  showUnseenCountInInfoBlocks(count) {
    if(count === 0) {
      return;
    }
    let infoBlocks = document.querySelector('.info-blocks');
    if(!infoBlocks) {
      return; // a page without info blocks
    }
    if(location.pathname === '/notifications') {
      return; // already on the notifications page
    }
    let infoBlock = document.createElement('a');
    infoBlock.setAttribute('class', 'info-block info-block--notification');
    infoBlock.setAttribute('href', '/notifications');
    infoBlock.innerHTML = count === 1 ? 'Sie haben <strong>eine</strong> neue Benachrichtigung!' : 'Sie haben <strong>' + count + '</strong> neue Benachrichtigungen!';
    infoBlocks.appendChild(infoBlock);
  }
}
