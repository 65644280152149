export default class Breadcrumbs {
  static init() {
    Array.prototype.forEach.call(document.querySelectorAll('.breadcrumbs--ellipsis'), el => {
      new Breadcrumbs(el);
    });
  }

  constructor(element) {
    if (!element) return;
    this.element = element;
    let breadcrumbsLastElement = this.element.querySelector('li:last-child');

    // 44px is width of ellipsis symbol
    if (breadcrumbsLastElement.getBoundingClientRect().right < this.element.getBoundingClientRect().right - 44) {
      this.element.classList.remove('breadcrumbs--ellipsis');
    }
  }
}
