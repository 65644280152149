/**
 * Install prompt for iOS, since there's no native solution for iOS
 */
export default class InstallPrompt {
  element;

  constructor() {
    const isStandalone = (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches);
    const isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if(isStandalone || !isIos || (window.Capacitor && Capacitor.isNative)) {
      return; // already installed
    }

    this.element = document.querySelector('.install-prompt');
    if(!this.element) {
      return;
    }

    if(window.localStorage && localStorage.getItem('install-prompt-was-closed')) {
      return;
    }

    this.element.querySelector('.install-prompt__close').addEventListener('click', () => this.hide());
    this.show();
  }

  show() {
    document.body.classList.add('body--prevent-scroll');
    this.element.classList.remove('install-prompt--is-hidden');
  }

  hide() {
    localStorage.setItem('install-prompt-was-closed', '1');
    document.body.classList.remove('body--prevent-scroll');
    this.element.classList.add('install-prompt--is-hidden');
  }
}
