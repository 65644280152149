export function register() {
  window.addEventListener('load', () => {
    const swUrl = `/service-worker.js`;

    navigator.serviceWorker
      .register(swUrl)
      .then(registration => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          // Prevents Chrome's "Update on reload" from going into infinite loop
          let lockRefresh = false;
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'activated') {
              if (lockRefresh) {
                return;
              }
              lockRefresh = true;
              // serviceWorker.controller points to the current SW. If it exists, it means it's an update.
              if (navigator.serviceWorker.controller) {
                console.log('SW update activated, reloading', VERSION);
                // Now that we now it's not a first app install, it's time to reload to get the fresh app assets
                window.location.reload();
              } else {
                console.log('Fresh SW activated.', VERSION);
              }
            }
          };
        };
      })
      .catch(error => {
        console.error('Error during service worker registration:', error);
      });
  });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error(error.message);
      });
  }
}
