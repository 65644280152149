window.addEventListener('load', () => {
	const isMerchandiseShopPage = document.body.classList.contains('merchandise-shop__page');
	if (isMerchandiseShopPage) window.merchandiseShopPage = new MerchandiseShopPage()
});

class MerchandiseShopPage {
	constructor()
	{
		window.addEventListener('click', this.onSpecElementClick.bind(this))

		// Init
		// 1. Select first spec of specs
		const specItems = document.querySelectorAll('.merchandise-shop__spec-item')
		let specs = {}
		specItems.forEach((specItem) => {
			const spec = specItem.dataset.spec
			specs[spec] = spec
		})
		Object.values(specs).forEach(spec => {
			const firstSpecItem = document.querySelector(`.merchandise-shop__spec-item[data-spec="${spec}"]`)
			this.selectSpecItemElement(firstSpecItem)
		})


	}

	onSpecElementClick(event)
	{
		const element = event.target
		if (!element || !element.classList.contains('merchandise-shop__spec-item')) {
			return
		}

		this.selectSpecItemElement(element)
	}

	unselectSpecItemElements(spec)
	{
		const specElements = document.querySelectorAll(`.merchandise-shop__spec-item[data-spec="${spec}"]`)
		const formField = document.querySelector(`[name$="[specs][${spec}]"]`)
		specElements.forEach((specElement) => {
			specElement.classList.remove('merchandise-shop__spec-item--selected')
		})
		formField.value = ''
	}

	selectSpecItemElement(element)
	{
		const spec = element.dataset.spec;
		const value = element.dataset.value;
		const formField = document.querySelector(`[name$="[specs][${spec}]"]`)

		// Unselect all items
		this.unselectSpecItemElements(spec)

		// Select current item
		element.classList.add('merchandise-shop__spec-item--selected')
		formField.value = value;
	}
}
