window.addEventListener('load', () => {
	window.voucherItemPage = new VoucherItemPage()
});

function toEUR(value) {
	return `€ ${value.toFixed(2).replace('.', ',')}`
}

class VoucherItemPage {
	constructor() {
		this.savingsElement = document.querySelector('.voucher-page__overview-savings');

		// Omit if no savings element was found
		if(!this.savingsElement) {
			return;
		}

		this.amountElement = document.querySelector('.voucher-page__overview-amount');
		this.totalElement = document.querySelector('.voucher-page__overview-total');
		this.formContainer = document.querySelector('.voucher-page__formContainer');
		this.formItemsField = document.querySelector('.voucher-page__form-voucherItemsField');
		this.formSubmitButton = document.querySelector('.voucher-page__form-submitButton');
		this.overview = document.querySelector('.voucher-page__overview');
		this.overviewList = document.querySelector('.voucher-page__overview-list');
		this.btnCart = document.querySelector('.voucher-page__btn-cart')

		this.btnCart.addEventListener('click', function () {
			window.scrollTo({
				behavior: 'smooth',
				left: 0,
				top: window.voucherItemPage.overview.offsetTop
			})
		})

		var voucherItems = document.querySelectorAll('.voucher-item');
		this.voucherItems = [];
		voucherItems.forEach((el) => {
			var item = new VoucherItem(el);
			this.voucherItems.push(item);
		});

		window.addEventListener('voucherItemChange', this.onVoucherItemChange.bind(this));
	}

	onVoucherItemChange() {
		this.render();
	}

	render() {
		this.savingsElement.innerHTML = toEUR(this.savings * -1);
		this.amountElement.innerHTML = toEUR(this.sum);
		this.totalElement.innerHTML = toEUR(this.sum - this.savings);
		this.formItemsField.setAttribute('value', JSON.stringify(this.voucherItems));

		this.overviewList.innerHTML = '';
		this.voucherItems.forEach(item => {
			if(item.sum > 0) {
				var markup = `
					<div class="voucher-page__overview-list__item">
						<span>${item.company}</span>
						<span>${toEUR(item.sum)}</span>
					</div>
				`
				this.overviewList.innerHTML += markup;
			}
		})

		if(this.canSubmit) {
			this.formContainer.classList.add('voucher-page__formContainer--is-visible');
			this.btnCart.classList.add('voucher-page__btn-cart--visible');
		} else {
			this.formContainer.classList.remove('voucher-page__formContainer--is-visible');
			this.btnCart.classList.remove('voucher-page__btn-cart--visible');
		}
	}

	get savings() {
		return this.voucherItems.reduce((accumulator, currentValue) => {
			return accumulator + currentValue.savings;
		}, 0);
	}

	get sum() {
		return this.voucherItems.reduce((accumulator, currentValue) => {
			return accumulator + currentValue.sum;
		}, 0);
	}

	get canSubmit() {
		return this.sum > 0;
	}
}

class VoucherItem {
	constructor(element) {
		this.element = element

		this.company = element.dataset.company;
		this.discount = parseInt(element.dataset.discount);

		this.sumElement = element.querySelector('.voucher-item__sum-value')

		this.voucherItemVariants = []
		var voucherItemVariants = element.querySelectorAll('.voucher-item__variants-item');
		voucherItemVariants.forEach((el) => {
			var item = new VoucherItemVariant(el, this);
			this.voucherItemVariants.push(item);
		});

		window.addEventListener('voucherItemVariantChange', this.onVoucherItemVariantChange.bind(this));
	}

	get sum() {
		return this.voucherItemVariants.reduce((accumulator, currentValue) => {
			return accumulator + currentValue.sum;
		}, 0);
	}

	get savings() {
		return this.voucherItemVariants.reduce((accumulator, currentValue) => {
			return accumulator + currentValue.savings;
		}, 0);
	}

	onVoucherItemVariantChange() {
		this.render()
		this.triggerChangeEvent()
	}

	triggerChangeEvent() {
		var event = new Event('voucherItemChange')
		window.dispatchEvent(event);
	}

	render () {
		this.sumElement.innerHTML = toEUR(this.sum)
		if(this.sum > 0) {
			this.element.classList.add('voucher-item--selected')
		} else {
			this.element.classList.remove('voucher-item--selected')
		}
	}
}

class VoucherItemVariant {
	constructor(element, voucherItem) {
		this.element = element
		this.formElement = element.querySelector('.voucher-item__variants-item-form');

		this.company = voucherItem.company;
		this.discount = voucherItem.discount;
		this.amount = parseFloat(this.formElement.dataset.amount);

		this.increaseButton = this.formElement.querySelector('.voucher-item__variants-item-form-increaseBtn');
		this.decreaseButton = this.formElement.querySelector('.voucher-item__variants-item-form-decreaseBtn');
		this.inputField = this.formElement.querySelector('.voucher-item__variants-item-form-inputField');

		this.inputField.addEventListener('change', this.onInputFieldChange.bind(this));
		this.decreaseButton.addEventListener('click', this.decrease.bind(this));
		this.increaseButton.addEventListener('click', this.increase.bind(this));

		this.setValue(0);
	}

	setValue(value) {
		this.value = Number.parseInt(value);
		this.inputField.value = this.value;
		if (this.value === 0) {
			this.decreaseButton.setAttribute('disabled', 'disabled');
		} else {
			this.decreaseButton.removeAttribute('disabled');
		}
		this.triggerChangeEvent()
		this.render()
	}

	decrease() {
		const value = Number.parseInt(this.value)
		var decreasedValue = value > 0 ? value - 1 : 0;
		this.setValue(decreasedValue);
	}

	increase() {
		const value = Number.parseInt(this.value)
		var increasedValue = value + 1;
		this.setValue(increasedValue);
	}

	onVoucherItemFormFieldChange(event) {
		this.value = event.target.value;
	}

	onInputFieldChange(event) {
		this.setValue(event.target.value)
	}

	triggerChangeEvent() {
		var event = new Event('voucherItemVariantChange')
		window.dispatchEvent(event);
	}

	get savings() {
		return this.value * this.amount * (this.discount / 100);
	}

	get sum() {
		return this.value * this.amount;
	}

	render() {
		if(this.value > 0) {
			this.element.classList.add('voucher-item__variants-item--selected')
		} else {
			this.element.classList.remove('voucher-item__variants-item--selected')
		}
	}
}
