const getVersion = () => fetch(
    `${process.env.PUBLIC_URL}/_Resources/Static/Packages/CodeQ.Site/Frontend/build/version.json`, {
    headers: {
      accept: 'application/json',
    }
  })
  .then(response => {
    if (!response.ok) {
      return;
    }
    return response.json();
  })
  .catch(() => {
    return null;
  });

let version = null;
let cacheTime = null;
const getVersionThrottled = async () => {
  const now = new Date().getTime();
  if (!cacheTime || now - cacheTime > 60 * 1000) {
    cacheTime = now;
    version = await getVersion();
  }
  return version;
};

const checkVersion = async () => {
  const version = await getVersionThrottled();

  // VERSION is a global constant injected by webpack
  if (version && version !== VERSION) {
    console.log('New version, reloading: ', VERSION, version);
    try {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration) {
        await registration.update();
        console.log('Registration update successful');
      }
    } catch (e) {
      console.log('Failed updating SW, reloading', e);
      location.reload();
    }
  }
};

export default checkVersion;
