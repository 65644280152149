import autocomplete from 'autocompleter';

/**
 * If the list has entries, show the text as first entry
 */
const showTextAsFirstEntry = (items, text) => {
  if (!items || !items.length) {
    return [];
  }
  let firstSuggestionIsText = items[0].toLowerCase() === text.toLowerCase();
  return firstSuggestionIsText ? items : [text].concat(items);
};

export default class Search {
  static init() {
    Array.prototype.forEach.call(document.querySelectorAll('.search__form'), el => {
      new Search(el);
    });
  }

  constructor(element) {
    if (!element) return;
    this.element = element;
    this.modal = document.querySelector('.header__search-modal');
    this.modalBtns = document.querySelectorAll('.header__search-modal-button');
    this.input = this.element.querySelector('.search__form__input');
    const autocompleteUri = this.element.dataset.autocompleteUri;

    // Autocomplete doesn't expose the text and items, so we need to keep
    // a reference our self for instant updating
    const localCache = [];
    let items = [];
    let fetchingCounter = 0;
    let fetchedText = '';

    window.addEventListener('click', (e) => {
      if (this.isModalExist()) {
        let isClickInside = this.modal.contains(e.target);
        let isClickTrigger = Array.prototype.find.call(this.modalBtns, el => el.contains(e.target));
        let isClickOnContainer = this.modal === e.target;

        if (isClickTrigger) {
          e.preventDefault();
          this.showModal()
        } else {
          this[isClickInside && !isClickOnContainer ? 'showModal' : 'hideModal']();
        }
      }
    });

    autocomplete({
      input: this.input,
      className: 'search__form__suggestions',
      minLength: 3,
      debounceWaitMs: 200,
      showOnFocus: true,
      //emptyMsg: 'No elements found',
      preventSubmit: false,
      renderGroup: function (groupName, currentValue) {
        let ul = document.createElement("UL");
        ul.textContent = groupName;
        return ul;
      },
      render: function (item, currentValue) {
        let li = document.createElement("LI");
        li.textContent = '' + item;
        return li;
      },
      fetch: function (text, update) {
        if (!text) {
          items = [];
          update(items);
          return;
        }

        fetchingCounter++;
        if (localCache[text]) {
          fetchedText = text;
          items = localCache[text];
          if (!Array.isArray(items)) {
            console.error('Cached item for ' + text + ' is invalid:');
            console.error(items);
          } else {
            update(items);
            return;
          }
        }

        // get new suggestions from the server
        let myFetchingCounter = fetchingCounter;
        fetch(`${autocompleteUri}&term=${encodeURIComponent(text)}`)
          .then(response => response.json())
          .then(result => {
            console.log(result);
            if (myFetchingCounter === fetchingCounter) {
              fetchedText = text;
              items = showTextAsFirstEntry(result.completions, text);
              localCache[text] = items;
              update(items);
            }
          });
      },
      onSelect: (item, input) => {
        if (fetchedText !== input.value && items && items[0] === fetchedText) {
          // the last fetch did not finish yet, and it's the first item
          // so probably that's the preselect from old data, ignore this
        } else {
          // use the selected text
          this.input.value = item;
        }
        this.element.submit();
      },
      customize: (input, inputRect, container, maxHeight) => {
        if (input.closest('.header--fixed')) {
          container.classList.add('search__form__suggestions--is-fixed');
        }
      }
    });
  }

  showModal() {
    if (!this.isInsideModal() || this.isModalActive()) return;
    this.modal.classList.add('header__search-modal--active');
    let modalInput = this.modal.querySelector('.search__form__input');
    modalInput.focus();
    let temp = modalInput.value;
    modalInput.value = '';
    modalInput.value = temp;
  }

  hideModal() {
    if (!this.isModalActive()) return;
    this.modal.classList.remove('header__search-modal--active');
  }

  toggleModal() {
    if (!this.isInsideModal()) return;
    this[this.isModalActive ? 'hideModal' : 'showModal']();
  }

  isModalActive() {
    return this.modal.classList.contains('header__search-modal--active');
  }

  isModalExist() {
    return !!this.modal;
  }

  isInsideModal() {
    function childOf(c, p) {
      while ((c = c.parentNode) && c !== p) ;
      return !!c;
    }

    return childOf(this.element, this.modal);
  }
}
