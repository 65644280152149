import "cookieconsent";

const googleAnalyticsId = 'G-0YZB8FJE8H';
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-0YZB8FJE8H');


const loadGoogleAnalytics = () => {
    // load google analytics tracking script
    (function (w, d, s, googleAnalyticsId) {
        let f = d.getElementsByTagName(s)[0];
        let el = d.createElement(s);
        el.async = true;
        el.src = 'https://www.googletagmanager.com/gtag/js?id=' + googleAnalyticsId;
        f.parentNode.insertBefore(el, f);
    })(window, document, 'script', googleAnalyticsId);
};

export default class CookieConsent {
    constructor() {
      const config = {
            position: 'bottom',
            theme: 'classic',
            palette: {
              popup: {
                background: "#484848",
                text: "#ffffff"
              },
              button: {
                background: "#75b94e",
                text: "#ffffff"
              }
            },
            content: {
                message: 'Diese Website verwendet Cookies. Weitere Informationen zu Cookies erhalten Sie in unserer Datenschutzerklärung.',
                link: 'Mehr erfahren',
                href: 'https://wirmobile.at/kontakte/datenschutzerklaerung',
                deny: 'Ablehnen',
                allow: 'Akzeptieren',
                close: false,
                target: '_blank',
            },
            revokeBtn: '<div style="display: none;"></div>',
            type: 'opt-in',
            onInitialise: function (status) {
                if(this.hasConsented()) {
                    loadGoogleAnalytics();
                }
            },
            onStatusChange: function () {
                if(this.hasConsented()) {
                    loadGoogleAnalytics();
                }
            },
        };
        if(location.pathname === '/kontakte/impressum' || location.pathname === '/kontakte/datenschutzerklaerung') {
          config.container = document.querySelector('.info-blocks');
          config.revokeBtn = '<div class="cc-revoke" style="position: relative;">Cookie Nutzung ändern</div>';
        }
        window.cookieconsent.initialise(config);
    }
}
