/**
 * Random Ads
 *
 * We persist a list of adds to show in local storage, and randomly select one.
 */
export default class RandomAd {
  getAds() {
    let ads = window.ads || {};
    let adsFromLocalStorage = {};
    try {
      let adsFromLocalStorage = JSON.parse(localStorage.getItem('ads'));
    } catch (e) {
      console.warn('Local storage not accessible due to browser settings. Not fetching ads in local storage.');
    }

    return adsFromLocalStorage || ads;
  }

  updateAds(newAds) {
    try {
      localStorage.setItem('ads', JSON.stringify(newAds));
    } catch(e) {
      console.warn('Local storage not accessible due to browser settings. Not saving ads in local storage.');
    }
  }

  getRandomAd(ads, height) {
    const customersWithMatchingAdFormat = ads.filter(customer => {
      return !!customer.ads.find(ad => ad.height === height);
    });

    if(customersWithMatchingAdFormat.length === 0) {
      return false;
    }

    const pool = [];
    customersWithMatchingAdFormat.forEach((customer, index) => {
      // each customer can get up to three bets into the pool
      pool.push(index);
      if(customer.package === 'default') return;
      pool.push(index);
      if(customer.package === 'premium') return;
      pool.push(index); // premiumplus
    })

    const winnerPoolIndex = Math.floor(Math.random() * pool.length);
    const winnerIndex = pool[winnerPoolIndex];
    return customersWithMatchingAdFormat[winnerIndex].ads.find(ad => ad.height === height);
  }

  initAll() {
    let ads = window.ads;
    if(ads) {
      // sync to local storage
      this.updateAds(window.ads);
    } else {
      ads = this.getAds();
    }

    Array.from(document.querySelectorAll('.random-ad')).forEach((el) => {
      const chosenAd = this.getRandomAd(ads, el.getAttribute('data-height'));
      if(chosenAd) {
        el.innerHTML = chosenAd.html;
      } else {
        el.remove();
      }
    });
  }
}
