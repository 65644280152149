import '../styles/style.scss';

import '../../../../../../../Packages/Plugins/BetterEmbed.NeosEmbed/Resources/Private/Assets/Main';
import '../../../../../../../Packages/Application/CodeQ.Survey/Resources/Private/Fusion/Root';
import '../../../../../../../Packages/Application/CodeQ.Voucher/Resources/Private/Fusion/Root';
import '../../../../../../../Packages/Application/CodeQ.MerchandiseShop/Resources/Private/Fusion/Root';

// IE polyfills
import 'element-closest-polyfill';

// custom
import './helpers/capacitor-server-page';
import './helpers/vh';
import './helpers/navigator-share-polyfill';
import './redirectToHome';
import './pushNotifications';
import * as serviceWorker from './serviceWorker';
import checkVersion from './checkVersion';
import PrecacheWorker from './precache.worker.js';
import servicesWorkerEnabled from './helpers/service-worker-enabled';
import smoothscroll from 'smoothscroll-polyfill';

import Article from './component/article';
import Breadcrumbs from "./component/breadcrumbs";
import CookieConsent from './component/cookie-consent';
import EventPage from './component/event-page';
import Notification from './component/notification';
import InstallPrompt from './component/install-prompt';
import RandomAd from './component/random-ad';
import Slider from './component/slider';
import Gallery from './component/gallery';
import Search from './component/search'

// Signals to script in index.html that the app assets have been loaded
window.APP_LOADED = true;

if (window.Sentry && Sentry.init) {
  Sentry.init({
    dsn: 'https://5f22a96ee45948d98c637027ee297a5f@o336756.ingest.sentry.io/5496008',
    tracesSampleRate: 1.0,
  });
}

// offline-ready worker setup
if (servicesWorkerEnabled()) {
  serviceWorker.register();

  // start precaching after 5sec in a background worker
  const precacheWorker = new PrecacheWorker();
  setTimeout(() => precacheWorker.postMessage('precache'), 5 * 1000);

  // check if an update exists
  setTimeout(checkVersion, 10 * 1000); // initial check after 10sec
  setInterval(checkVersion, 15 * 60 * 1000); // every 15min if open on the main thread
}

// ask the user to install the pwa
window.addEventListener('beforeinstallprompt', e => {
  e.userChoice.then(choiceResult => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'A2H',
      outcome: choiceResult.outcome,
    });
  });
});

// init application
class App {
  constructor() {
    window.addEventListener('load', () => {
      document.body.classList.add('is-loaded');
    })

    // "hover" effect for touch devices
    let elements = document.querySelectorAll('.link__list a, .article__author a, .article__content a, .header__logo, .link-box');
    Array.prototype.forEach.call(elements, (el) => {
      el.addEventListener('touchstart', (e) => {
        e.currentTarget.focus({preventScroll: true})
      });

      el.addEventListener('touchend', (e) => {
        e.currentTarget.blur({preventScroll: true})
      });
    });

    // sliders init
    window.sliders = [];
    Array.prototype.forEach.call(document.querySelectorAll('.glide'), (el) => {
      window.sliders.push(new Slider(el));
    });

    (new Article()).initAll();
    (new Notification()).initAll();
    new InstallPrompt();
    new CookieConsent();

    // init our ads delayed
    setTimeout(() => (new RandomAd()).initAll(), 300);

    // gallery init
    new Gallery().init('.gallery');

    new EventPage().init();

    // since some pages are network-first, add a unload event
    // see body--is-closing.scss
    let clickedLinkIsEmailOrPhone = false;
    window.addEventListener('beforeunload', (e) => {
      if (clickedLinkIsEmailOrPhone) {
        return;
      }
      if (document.body.classList.contains('neos-backend')) {
        return;
      }
      document.body.classList.add('body--is-closing');
    });

    // since iOS doesn't support the above, we also need to listen for click
    window.addEventListener('click', e => {
      clickedLinkIsEmailOrPhone = e.target && !!e.target.closest('a[href^=mailto],a[href^=tel]');
      if (clickedLinkIsEmailOrPhone) {
        return;
      }

      if (document.body.classList.contains('neos-backend')) {
        return;
      }

      if (e.target.closest('.article__gallery,.jonnitto-prettyembed,.codeq-survey-component')) {
        return; // we are inside an interactive element
      }

      const link = e.target.closest('a');
      if (!link) {
        return;
      }

      if (link.getAttribute('onclick')) {
        return;
      }
      if (link.classList.contains('jonnitto-prettyembed--youtube')) {
        return;
      }
      const href = link.getAttribute('href');
      if (!href) {
        // no href, e.g. cookie consent
        return;
      }

      if (link.getAttribute('target') === '_blank') {
        // external link
        return;
      }
      document.body.classList.add('body--is-closing');
    });


    // history.back loads the pages from the cache
    // in this case we need to unset .body--is-closing
    window.addEventListener('pageshow', (e) => {
      document.body.classList.remove('body--is-closing');
    });

    // reload the page when the app is resumed
    window.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "visible") {
        window.location.reload();
      }
    });

    Breadcrumbs.init();
    Search.init();
    smoothscroll.polyfill();
  }
}

new App();
