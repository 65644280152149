import * as PusherPushNotifications from "@pusher/push-notifications-web"

const showInfoBlock = (content) => {
  let infoBlocks = document.querySelector('.info-blocks');
  if(!infoBlocks) {
    return; // a page without info blocks
  }
  let infoBlock = document.createElement('div');
  infoBlock.setAttribute('class', 'info-block');
  infoBlock.innerHTML = content;
  infoBlocks.appendChild(infoBlock);
};

const initializePusher = (serviceWorkerRegistration) => {
  const beamsClient = new PusherPushNotifications.Client({
    instanceId: '125433d0-498c-491f-a6f8-6ad04abbb953',
    serviceWorkerRegistration: serviceWorkerRegistration,
  });

  beamsClient.start()
    // register device
    .then(beamsClient => beamsClient.getDeviceId())
    .then(deviceId => console.log('Successfully registered with Beams. Device ID:', deviceId))

    // register interest
    .then(() => beamsClient.getDeviceInterests())
    .then(interests => {
      if(interests.indexOf('important') === -1) {
        beamsClient.addDeviceInterest('important');
      }
      if(interests.indexOf('notifications') === -1) {
        beamsClient.addDeviceInterest('notifications');
      }
    })
    .then(() => beamsClient.getDeviceInterests())
    .then(interests => console.log('Current interests:', interests))

    /* check state for notifications page
    .then(() => beamsClient.getRegistrationState())
    .then(state => {
      let states = PusherPushNotifications.RegistrationState;
      switch(state) {
        case states.PERMISSION_DENIED: {
          // Notifications are blocked
          // Show message saying user should unblock notifications in their browser
          showPermissionRequestInfoBlock();

          break;
        }
        case states.PERMISSION_GRANTED_REGISTERED_WITH_BEAMS: {
          // Ready to receive notifications
          // Show "Disable notifications" button, onclick calls '.stop'
          break;
        }
        case states.PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS:
        case states.PERMISSION_PROMPT_REQUIRED: {
          // Need to call start before we're ready to receive notifications
          // Show "Enable notifications" button, onclick calls '.start'
          break;
        }
      }
    })
    */

    .catch((e) => {
      console.error(e);
      showInfoBlock('Beim Aktivieren der Push Notifications ist ein unerwarteter Fehler aufgetreten.');
    });
};

if('serviceWorker' in navigator) navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
  // Let's check if the browser supports notifications
  if (!('Notification' in window)) {
    return;
  }

  // push notification are disabled for now
  return;

  // Let's check whether notification permissions have already been granted
  if (Notification.permission === 'granted') {
    initializePusher(serviceWorkerRegistration);
    return;
  }

  // user denied, we can't request another permission request
  if (Notification.permission === 'denied') {
    showInfoBlock('Sie haben Push Notifications für diese Seite abgelehnt. Sie können Push Notifications in Ihrem Browser für diese Seite aktivieren.');
    return;
  }

  // Otherwise, we need to ask the user for permission
  Notification.requestPermission().then(function (permission) {
    showInfoBlock('Bitte akzeptieren Sie Push Notifications, damit wir Ihnen Benachrichtigungen schicken können');

    // If the user accepts, let's create a notification
    if (permission === 'granted') {
      initializePusher(serviceWorkerRegistration);
    } else {
      showInfoBlock('Sie haben Push Notifications für diese Seite abgelehnt. Sie können Push Notifications in Ihrem Browser für diese Seite aktivieren.');
    }
  });

});

