import Glide from "@glidejs/glide";

export default class Slider {
  constructor(element) {
    if (!element) return;
    this.sliders = [];
    this.element = element;

    // in the Neos backend the element information is rendered in a appended script tag
    // since this script is executed after it's evaluation, we can safely remove it
    // to make the slider work properly
    const isNeosBackend = document.body.classList.contains('neos-backend');
    if (isNeosBackend) {
      Array.from(document.querySelectorAll('script[data-neos-nodedata]')).forEach((script) => script.remove())
    }

    let bulletCount = element.querySelectorAll('.glide__slide').length;
    let bulletWrapper = element.parentElement.querySelector('.glide__bullets');

    if (bulletCount > 1) {
      if (bulletWrapper) {
        for (let index = 0; index < bulletCount; index++) {
          const button = document.createElement('button');
          button.className = 'glide__bullet';
          button.setAttribute("data-glide-dir", '=' + index);

          bulletWrapper.appendChild(button)
        }
      }
    } else {
      if (bulletWrapper) {
        bulletWrapper.remove();
      }
    }

    let options = {
      peek: 0,
      gap: 0,
      arrows: false,
      rewind: false,
      type: 'carousel',
      startAt: 0,
      perView: 1,
      autoplay: isNeosBackend ? false : 11000,
    };

    let slider = new Glide(element, options)
    this.slider = slider.mount();

    return {slider: this.slider, type: 'hero'};
  }

  get() {
    return this.slider;
  }
}

